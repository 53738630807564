const API_URL = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api`;

export const Endpoint = Object.freeze({
  /* Auth */
  login: { url: `${API_URL}/users/login`, method: "POST" } /* Auth login */,
  register: { url: `${API_URL}/users/register`, method: "POST" } /* Auth register */,
  logout: { url: `${API_URL}/users/logout`, method: "POST" } /* Auth logout */,
  newAccessToken: { url: `${API_URL}/users/token`, method: "POST" } /* Auth new access token */,
  verifyAccount: { url: `${API_URL}/users/email-verification`, method: "GET" } /* verify account */,
  resetPassword: { url: `${API_URL}/users/reset-password`, method: "POST" } /* reset password */,
  forgotUsername: { url: `${API_URL}/users/forgot-username`, method: "POST" } /* forgot username */,
  checkResetPasswordToken: { url: `${API_URL}/users/reset-password-check-token`, method: "GET" } /* check reset password token */,
  requestNewPassword: { url: `${API_URL}/users/request-new-password`, method: "POST" } /* request new password */,
  me: { url: `${API_URL}/users/me`, method: "GET" } /* get user info */,
  updateAccount: { url: `${API_URL}/users/update-account`, method: "PUT" } /* update account */,
  switchAccount: { url: `${API_URL}/users/switch-account`, method: "POST" } /* switch account */,
  deleteAccount: { url: `${API_URL}/users/delete`, method: "DELETE" } /* delete account */,
  updatePassword: { url: `${API_URL}/users/update-account-password`, method: "PUT" } /* update password */,
  verifyAccountAndEnterPassword: { url: `${API_URL}/users/verify-account-and-enter-password`, method: "POST" },
  resendEmailAccountVerify: { url: `${API_URL}/users/resend-email-account-verification`, method: "POST" },

  /* Admin */
  admin: {
    users: { url: `${API_URL}/admin/users`, method: "GET" } /* Load all users, except admins and superAdmins */,
    restaurants: { url: `${API_URL}/admin/restaurants`, method: "GET" } /* Load restaurants */,
    statistics: { url: `${API_URL}/admin/statistics`, method: "GET" } /* Load statistics */,
    verifyAccountToken: { url: `${API_URL}/admin/verify-account-token`, method: "GET" },
    completeAccount: { url: `${API_URL}/admin/complete-account`, method: "POST" } /* Complete account as admin */,
    create: { url: `${API_URL}/admin/create`, method: "POST" } /* Create an admin */,
    load: { url: `${API_URL}/admin/load`, method: "GET" } /* Load all admins */,
    resendInvitation: { url: `${API_URL}/admin/resend-invitation`, method: "POST" } /* Resend email verification to an admin */,
    delete: { url: `${API_URL}/admin/delete`, method: "DELETE" } /* Delete an admin as superAdmin */,
    shapeshift: { url: `${API_URL}/admin/shapeshift`, method: "POST" } /* Ability to login as another user */,
    editEmailTemplate: { url: `${API_URL}/admin/edit-email-template`, method: "POST" },
    getEmailTemplate: { url: `${API_URL}/admin/get-email-template`, method: "GET" },
    dietary: {
      create: { url: `${API_URL}/admin/dietary/create`, method: "POST" } /* Create dietary considerations as admin */,
      read: { url: `${API_URL}/admin/dietary`, method: "GET" } /* Get the first table of dietary */,
    },
    user: {
      delete: { url: `${API_URL}/admin/delete/user`, method: "POST" } /* Delete an user (owner, admin or member) */,
      add: { url: `${API_URL}/admin/add-user`, method: "POST" } /* Add new user */,
      subscribe: { url: `${API_URL}/admin/subscribe-user`, method: "POST" },
    },
    logs: {
      read: { url: `${API_URL}/admin/loggers`, method: "GET" } /* Read logs */,
      delete: { url: `${API_URL}/admin/logs`, method: "POST" } /* Read logs */,
    },
    DMA: {
      list: { url: `${API_URL}/admin/dma-list`, method: "GET" } /* List all the DMA-S */,
      list_pending: { url: `${API_URL}/admin/dma-list-pending`, method: "GET" } /* List all the DMA-S pending */,
      delete: function (id) {
        return { url: `${API_URL}/admin/dma-delete/${id}`, method: "DELETE" }; /* Delete a DMA */
      },
      register: { url: `${API_URL}/admin/send-dma-register-link`, method: "POST" } /* Send a link to register a DMA */,
      cancel_dma_invitation: function (token) {
        return { url: `${API_URL}/admin/cancel-dma-invitation?token=${token}`, method: "POST" }; /* Cancel a DMA invitation */
      },
      deleteClient: { url: `${API_URL}/admin/delete-dma-client`, method: "POST" } /* Delete a client from a DMA */,
    },
  },

  /* DMA */
  DMA: {
    verifyToken: function (token) {
      return { url: `${API_URL}/dma/verify-token?token=${token}`, method: "GET" }; /* Verify token */
    },
    register: function (token) {
      return { url: `${API_URL}/dma/register?token=${token}`, method: "POST" }; // Register a DMA
    },
    product_prices: { url: `${API_URL}/dma/product-prices`, method: "GET" }, // Get product prices
    check_promo_code: { url: `${API_URL}/dma/check-promo-code`, method: "POST" }, // Check promo code
    clients: {
      list: { url: `${API_URL}/dma/clients`, method: "GET" } /* List all the clients */,
      create: { url: `${API_URL}/dma/add-client`, method: "POST" } /* Create a client */,
      delete: function (id) {
        return { url: `${API_URL}/dma/delete-client/${id}`, method: "DELETE" }; /* Delete a client */
      },
      shapeshift: { url: `${API_URL}/dma/client-shapeshift`, method: "POST" } /* Ability to login as another user */,
      statistics: { url: `${API_URL}/dma/clients-statistics`, method: "GET" } /* Get client statistics */,
      temporary: { url: `${API_URL}/dma/clients-temporary`, method: "GET" } /* Get temporary clients */,
      verify_temporary_token: { url: `${API_URL}/dma/client-temporary-verify-token`, method: "GET" },
      accepted_invitation: { url: `${API_URL}/dma/client-accept-invitation`, method: "POST" },
      rejected_invitation: { url: `${API_URL}/dma/client-reject-invitation`, method: "POST" },
      subscription_status: { url: `${API_URL}/dma/client-subscription-status`, method: "GET" },
      create_subscription: { url: `${API_URL}/dma/clients-create-subscription`, method: "POST" },
      single: function (id) {
        return { url: `${API_URL}/dma/single-client?id=${id}`, method: "GET" };
      },
      deleteSubscription: function (id) {
        return { url: `${API_URL}/dma/delete-client-subscription/${id}`, method: "DELETE" };
      },
      cancelInvitation: function (token) {
        return { url: `${API_URL}/dma/client-cancel-invitation?token=${token}`, method: "POST" };
      },
    },
  },

  /* Subscription */
  subscription: {
    hasActive: { url: `${API_URL}/subscription/has-active`, method: "GET" } /* Check if there is an active subscription */,
    customerPortalSession: { url: `${API_URL}/subscription/customer-portal-session`, method: "POST" }, // Create a session for the customer portal
    checkoutSession: { url: `${API_URL}/subscription/checkout-session`, method: "POST" }, // Create a session for the checkout
    customerPaymentMethods: { url: `${API_URL}/subscription/customer-payment-methods`, method: "GET" }, // Get the customer payment methods
    savePayment: { url: `${API_URL}/subscription/save-payment`, method: "POST" }, // Save a payment method
    baseProduct: { url: `${API_URL}/subscription/base-product`, method: "GET" }, // Get the base product
  },

  /* Settings */
  settings: {
    quickEdit: {
      get: { url: `${API_URL}/settings/quick-edit`, method: "GET" } /* Get the quick edit settings */,
      update: { url: `${API_URL}/settings/quick-edit`, method: "PUT" } /* Update the quick edit settings */,
    },
  },

  /* Restaurant */
  getRestaurant: { url: `${API_URL}/restaurants`, method: "GET" } /* Get a restaurant */,
  updateRestaurant: { url: `${API_URL}/restaurants/update`, method: "POST" } /* Update a restaurant */,
  uploadMedia: { url: `${API_URL}/restaurants/upload-media`, method: "POST" } /* Upload media */,
  listMedia: { url: `${API_URL}/restaurants/media`, method: "GET" } /* List media */,
  deleteMedia: { url: `${API_URL}/restaurants/media`, method: "DELETE" } /* Delete media */,
  editMedia: { url: `${API_URL}/restaurants/media/edit`, method: "POST" } /* Edit media */,
  addRestaurant: { url: `${API_URL}/restaurants/add`, method: "POST" } /* Add a restaurant */,
  getQRTemplates: { url: `${API_URL}/restaurants/qr-templates`, method: "GET" } /* Get QR templates */,
  setCustomizations: { url: `${API_URL}/restaurants/set-customizations`, method: "POST" } /* Set customizations */,
  setFilter: { url: `${API_URL}/restaurants/set-filter`, method: "POST" } /* Set customizations */,

  maps: {
    get: { url: `${API_URL}/maps`, method: "GET" },
    post: { url: `${API_URL}/maps`, method: "POST" },
  },

  request_transfer_ownership: { url: `${API_URL}/restaurants/request-transfer-ownership`, method: "POST" },
  transfer_ownership_status: function (token) {
    return { url: `${API_URL}/restaurants/transfer-ownership-status?token=${token}`, method: "GET" };
  },
  temporary_transfer_ownership_status: { url: `${API_URL}/restaurants/temporary-transfer-ownership-status`, method: "GET" },
  accept_transfer_request: { url: `${API_URL}/restaurants/accept-transfer-request`, method: "POST" },

  cancel_transfer_ownership: { url: `${API_URL}/restaurants/cancel-transfer-request`, method: "POST" },

  /* Members */
  getMembers: { url: `${API_URL}/members`, method: "GET" } /* Get members */,
  createMember: { url: `${API_URL}/members`, method: "POST" } /* Create a member */,
  updateMember: { url: `${API_URL}/members`, method: "PUT" } /* Update a member */,
  deleteMember: { url: `${API_URL}/members`, method: "DELETE" } /* Delete a member */,
  verifyMemberToken: { url: `${API_URL}/members/verify`, method: "GET" } /* Verify a member token */,
  verifyMemberAccount: { url: `${API_URL}/members/verify`, method: "POST" } /* Verify a member account */,
  resendInvitation: { url: `${API_URL}/members/resend-invitation`, method: "POST" } /* Resend invitation */,

  /* Menus */
  createMenu: { url: `${API_URL}/menus`, method: "POST" } /* Create a menu */,
  updateMenu: { url: `${API_URL}/menus`, method: "PUT" } /* Update a menu */,
  deleteMenu: { url: `${API_URL}/menus`, method: "DELETE" } /* Delete a menu */,
  loadMenus: { url: `${API_URL}/menus`, method: "GET" } /* Load menus */,
  findSingleMenu: { url: `${API_URL}/menus`, method: "GET" } /* Find a single menu */,
  getTemplates: { url: `${API_URL}/restaurants/templates`, method: "GET" } /* Get templates */,
  updateMenuTimeline: { url: `${API_URL}/menus/update-timeline`, method: "POST" } /* Update menu timeline */,
  reorderMenu: { url: `${API_URL}/menus/reorder`, method: "POST" } /* Reorder menu */,
  selectCategories: { url: `${API_URL}/menus/select-categories`, method: "POST" } /* Select categories */,
  removeCategoryFromMenu: { url: `${API_URL}/menus/remove-category`, method: "POST" } /* Remove category from menu */,

  /* Categories */
  createCategory: { url: `${API_URL}/categories`, method: "POST" } /* Create a category */,
  findCategoryById: { url: `${API_URL}/categories`, method: "GET" } /* Find a category by id */,
  updateCategory: { url: `${API_URL}/categories`, method: "PUT" } /* Update a category */,
  loadCategories: { url: `${API_URL}/categories`, method: "GET" } /* Load categories */,
  deleteCategory: { url: `${API_URL}/categories`, method: "DELETE" } /* Delete a category */,
  removeDishFromCategory: { url: `${API_URL}/categories/removeDish`, method: "POST" } /* Remove dish from category */,
  addDishesToCategory: { url: `${API_URL}/categories/addDishes`, method: "POST" } /* Add dishes to category */,
  uploadCategoryCSV: { url: `${API_URL}/categories/upload-csv`, method: "POST" } /* Upload category CSV */,
  updateCategoriesTimeline: { url: `${API_URL}/categories/update-timeline`, method: "POST" } /* Update categories timeline */,

  /* Dishes */
  createDish: { url: `${API_URL}/dishes`, method: "POST" } /* Create a dish */,
  findDishById: { url: `${API_URL}/dishes`, method: "GET" } /* Find a dish by id */,
  updateDish: { url: `${API_URL}/dishes`, method: "PUT" } /* Update a dish */,
  deleteDish: { url: `${API_URL}/dishes`, method: "DELETE" } /* Delete a dish */,
  loadDishes: { url: `${API_URL}/dishes`, method: "GET" } /* Load dishes */,
  uploadDishCSV: { url: `${API_URL}/dishes/upload-csv`, method: "POST" } /* Upload dish CSV */,
  deleteDietary: { url: `${API_URL}/dishes/delete-dietary`, method: "POST" } /* Delete dietary */,
  updateDietary: { url: `${API_URL}/dishes/update-dietary`, method: "POST" } /* Update dietary */,

  /* Ingredients */
  createIngredient: { url: `${API_URL}/ingredients`, method: "POST" } /* Create an ingredient */,
  loadIngredients: { url: `${API_URL}/ingredients`, method: "GET" } /* Load ingredients */,
  updateIngredient: { url: `${API_URL}/ingredients`, method: "PUT" } /* Update an ingredient */,
  deleteIngredient: { url: `${API_URL}/ingredients`, method: "DELETE" } /* Delete an ingredient */,
  uploadIngredientCSV: { url: `${API_URL}/ingredients/upload-csv`, method: "POST" } /* Upload ingredient CSV */,

  /* Test */
  test: { url: `${API_URL}/users/test`, method: "POST" } /* Test */,

  /* Customers */
  getCustomerMenu: { url: `${API_URL}/menus/customer`, method: "GET" } /* Get customer menu */,
  customerDish: { url: `${API_URL}/menus/customer`, method: "GET" } /* Get customer dish */,
  getGapiAuthToken: { url: `${API_URL}/gapi/get-token`, method: "GET" } /* Get customer dish */,
});
