import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { store } from "../store-redux/connect/store";

const cookies = new Cookies();
const getAccessToken = () => cookies.get("accessToken");

const removeCookieTokens = () => {
  cookies.remove("accessToken");
  cookies.remove("refreshToken");
  localStorage.removeItem("user");
};

const humanReadableStatusCode = parseInt(process.env.REACT_APP_HUMAN_READABLE_ERROR);

const validateResponse = async (jsonResponse, statusCode, resource, options) => {
  let expiredToken = false;
  const { Auth } = store.getState();

  jsonResponse["statusCode"] = statusCode;

  if (statusCode === parseInt(process.env.REACT_APP_PERMISSION_DENIED_ERROR_STATUS)) {
    toast("Permission denied");
    return jsonResponse;
  }

  if (statusCode === 200) {
    return jsonResponse;
  }

  if (statusCode === 401) {
    removeCookieTokens();
    localStorage?.removeItem("menuSelected");
    window.location.href = "/login";
    return { confirmation: "Fail", message: `Something went wrong. Authorization failed.` };
  }

  if (statusCode === parseInt(process.env.REACT_APP_ERROR_CODE_TOKEN)) {
    expiredToken = true;
  }

  if (expiredToken) {
    const isRefreshed = await Auth.REFRESH_TOKEN();
    if (isRefreshed) return await Api.call(resource, options);
  }

  if (humanReadableStatusCode !== statusCode) {
    return { confirmation: "Fail", message: `Something went wrong.` };
  }

  return jsonResponse;
};

export const Api = {
  async call(resource, options = {}) {
    //eslint-disable-next-line
    const { data, params, query, headers } = options;

    const fetchOptions = {
      url: resource.url,
      method: resource.method,
      redirect: "follow",
    };

    const isFormData = data instanceof FormData;

    if (data) {
      fetchOptions.body = isFormData ? data : JSON.stringify(data);
    }

    if (params) {
      params.forEach((param) => (fetchOptions.url += `/${param}`));
    }

    fetchOptions.headers = new Headers();

    if (!isFormData) {
      fetchOptions.headers.append("Content-Type", "application/json");
    }

    fetchOptions.headers.append("Authorization", `Bearer ${getAccessToken()}`);

    if (query) {
      Object.keys(query).forEach((elem, index) => {
        let connector = index === 0 ? "?" : "&";
        fetchOptions.url += `${connector}${elem}=${query[elem]}`;
      });
    }

    try {
      const response = await fetch(fetchOptions.url, fetchOptions);
      const jsonResponse = await response.json();

      const statusCode = parseInt(response.status);
      return await validateResponse(jsonResponse, statusCode, resource, options);
    } catch (err) {
      return { confirmation: "Fail", message: "Something went wrong." };
    }
  },
};

export default Api;
