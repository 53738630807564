import { useState, useEffect, useRef } from "react";
import {
  AnalyticsDashboard,
  ActiveUsersChart,
  BounceRateChart,
  PagesPerSessionChart,
  SessionDurationChart,
  SessionsByDateChart,
  SessionsBySourceChart,
  SessionsByHourChart,
  PageViewsPerPathChart,
} from "react-analytics-charts";
import { useAnalyticsApi, useAuthorize } from "react-use-analytics-api";
import { Api, Endpoint } from "../../api";
import SelectList from "../../components/SelectList";
import withDashboardLayout from "../../Layouts/AdminPanel";
import helpers from "../../helpers/general";
import { Alert } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";

const GOOGLE_ANALYTICS_DUMMY_ENDPOINT = "https://content.googleapis.com/analytics/v3/data/ga";
const options = {
  colors: ["#f46d25", "#787878"],
  title: "",
};

const pieOptions = {
  colors: ["#f46d25", "#fcd8c5", "#f68a51", "#9b3c08", "#4e1e04", "#787878"],
  title: "",
  legend: { position: "bottom" },
};

const timeOptions = [
  { value: "7", label: "Last 7 days" },
  { value: "30", label: "Last 30 days" },
  { value: "90", label: "Last 90 days" },
  { ...helpers.generateTimeOptions.thisWeek() },
  { ...helpers.generateTimeOptions.thisMonth() },
  { ...helpers.generateTimeOptions.thisQuarter() },
  { ...helpers.generateTimeOptions.thisYear() },
];

function Analytics() {
  const { ready, gapi, authorized, error } = useAnalyticsApi();
  const [gapiToken, setGapiToken] = useState();
  const [authorizeCalled, setAuthorizeCalled] = useState(false);
  const [canAccessAnalytics, setCanAccessAnalytics] = useState(true);
  const [time, setTime] = useState({ value: "7", label: "Last 7 days" });
  const [titles, setTitles] = useState(null);
  const wrapper = useRef(null);
  const [loading, setLoading] = useState(true);
  const viewId = `ga:${process.env.REACT_APP_GOOGLE_ANALYTICS_ADMIN_VIEW}`;

  useEffect(() => {
    setTitles({
      activeUsers: `Active users (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
      bounceRate: `% of customers who viewed only one page (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
      pagesPerSession: `Number of items viewed per user/customer (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
      averageSessionDuration: `Average user/customer time spent (seconds) (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
      sessionByHour: `Number of page views per hour (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
      sessions: `Number of page views in (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
      sessionsBySource: `How users/customers found foodi (${time?.value} Day${time?.value !== 1 ? "s" : ""})`,
    });
  }, [time]);

  useEffect(() => {
    (async () => {
      const response = await Api.call(Endpoint.getGapiAuthToken);
      if (response?.statusCode === 200) {
        setGapiToken(response?.message);
      }
    })();
  }, []);

  const authorize = useAuthorize(gapi, {
    serverAuth: {
      access_token: gapiToken,
    },
  });

  useEffect(() => {
    if (ready && error === undefined && !authorizeCalled && gapiToken) {
      authorize();
      setAuthorizeCalled(true);
    }
  }, [ready, error, authorizeCalled, authorize, gapiToken]);

  const checkAnalyticsConnection = async () => {
    const requestOptions = {
      method: "GET",
      headers: new Headers(),
      redirect: "follow",
    };

    requestOptions.headers.append("Authorization", `Barer ${gapiToken}`);

    fetch(GOOGLE_ANALYTICS_DUMMY_ENDPOINT, requestOptions).catch((error) => {
      if (error.message === "Failed to fetch") {
        setCanAccessAnalytics(false);
      }
    });
  };

  useEffect(() => {
    if (ready && error === undefined && authorizeCalled && gapiToken) {
      checkAnalyticsConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, error, authorizeCalled, gapiToken]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  if (loading) return <LoadingSpinner fullScreen={true} />;

  if (!authorized || !canAccessAnalytics)
    return (
      <Alert variant="info">
        <h4>
          It looks like you are using an <strong>ad-blocker</strong>!
        </h4>
        <h5>
          Please disable it to allow communication with <strong>GoogleAnalyticsData API</strong>
        </h5>
      </Alert>
    );

  return (
    <div ref={wrapper} style={{ marginBottom: 40 }}>
      <div style={{ maxWidth: 400, marginBottom: 40 }}>
        <SelectList placeholder="Select a time option..." value={time} onChange={setTime} options={timeOptions} isMulti={false} />
      </div>
      <AnalyticsDashboard
        hideViewSelector={true}
        hideAuth={true}
        viewId={viewId}
        authOptions={{
          serverAuth: {
            access_token: gapiToken,
          },
        }}
        renderCharts={(gapi, viewId) => (
          <>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.activeUsers}</h6>
              <ActiveUsersChart gapi={gapi} viewId={viewId} options={options} days={time?.value} />
            </div>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.bounceRate}</h6>
              <BounceRateChart gapi={gapi} viewId={viewId} options={options} days={time?.value} />
            </div>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.pagesPerSession}</h6>
              <PagesPerSessionChart gapi={gapi} viewId={viewId} options={options} days={time?.value} />
            </div>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.averageSessionDuration}</h6>
              <SessionDurationChart gapi={gapi} viewId={viewId} options={options} days={time?.value} />
            </div>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.sessions}</h6>
              <SessionsByDateChart gapi={gapi} viewId={viewId} showPageViews showUsers options={options} days={time?.value} />
            </div>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.sessionsBySource}</h6>
              <SessionsBySourceChart gapi={gapi} viewId={viewId} options={pieOptions} days={time?.value} />
            </div>
            <div className="widget">
              <h6 style={{ marginBottom: 0 }}>{titles?.sessionByHour}</h6>
              <SessionsByHourChart gapi={gapi} viewId={viewId} options={options} days={time?.value} />
            </div>
            <div className="widget">
              <PageViewsPerPathChart gapi={gapi} viewId={viewId} options={options} days={time?.value} />
            </div>
          </>
        )}
      />
    </div>
  );
}

export default withDashboardLayout(Analytics);
